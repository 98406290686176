import {useCallback, useState} from "react";
import {useCreateBookingMutation} from "features/api/bookings-api";
import {FormBooking} from "features/BookingFormProxy/types";
import {Notification} from "services/notification";
import {useDispatch} from "react-redux";
import {invalidateHallSlots} from "features/api/hallschema-api";
import {useCreateGuestMutation, useLazyFetchClientQuery} from "features/api/guest-api";
import {useBooleanState} from "hooks/useBooleanState";
import {useIntlUtils} from "../../../../../hooks/useIntlUtils";
import {ETranslations} from "../../../../../types/translates";

export function useCreateWithOverbooking() {
    const {getIntlActionFailed, getIntlJoinedParts} = useIntlUtils();
    const [createBooking] = useCreateBookingMutation();
    const [hasManageralError, setHasManageralError] = useState<boolean>(false);
    const [createGuest] = useCreateGuestMutation();
    const [overbookingData, setOverbookingData] = useState<FormBooking | undefined>();
    const dispatch = useDispatch();
    const [getClient] = useLazyFetchClientQuery()
    const [isCreating, start, stop] = useBooleanState();

    const closeModal = () => setHasManageralError(false);
    const create = useCallback(
        async (formData) => {
            start();
            const data = formData;
            try {
                if (!data?.client?.client_id && !data?.contact?.surname) {
                    await createGuest({...data.client, phone: data.phone}).then(async res => {
                        //@ts-ignore
                        await getClient(res?.data?.data.client_id).then(response => {
                            //@ts-ignore
                            data.client_id = res?.data?.data.client_id;
                            data.client = response.data;
                        })
                    })
                }

                await createBooking({data}).unwrap();
            } catch (e) {
                // @ts-ignore
                if (e?.data?.errorCode === 10400) {
                    setHasManageralError(true)
                }
                // @ts-ignore
                if (e?.data?.errorCode === 10100) {
                    setOverbookingData(data);
                }
                throw e;
            } finally {
                stop();
            }
        },
        [createBooking, setOverbookingData]
    );

    const clearData = useCallback(() => setOverbookingData(undefined), [setOverbookingData]);

    const createWithOverbooking = useCallback(async () => {
        if (!overbookingData) return undefined;
        start();
        try {
            const result = await createBooking({
                data: overbookingData,
                isOverbooking: true,
            }).unwrap();
            setOverbookingData(undefined);
            dispatch(invalidateHallSlots());
            return result;
        } catch (e) {
            Notification.error({
              title: getIntlActionFailed(
                getIntlJoinedParts([
                  ETranslations.BASE_CREATE,
                  ETranslations.PLURAL_BOOKING,
                ]).toLowerCase()
              ),
            });
            throw e;
        } finally {
            stop();
        }
    }, [overbookingData, createBooking]);

    return {
        create,
        createWithOverbooking,
        isConfirmationRequired: !!overbookingData,
        clearData,
        isCreating,
        hasManageralError,
        closeModal
    };
}
