import React from 'react';
import cn from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { Input, Labeled } from 'ui-kit';
import { ICONS } from 'common/helpers';
import { HideWhen } from '../../HideWhen';
import { isRealClient } from '../validators/validators';
import { ETranslations } from '../../../types/translates';
import useClient from './hooks/useClient';
import commonStyles from './style.module.scss';
import styles from './Client.module.scss';

const ClientInfoRedux = ({
  value,
  onChange,
  invalid,
  disabled,
  isCreateBooking,
  canEditNames = false,
  valid,
  isNewDesign = false,
  required,
  isWithPhone,
  props,
}: any) => {
  const {
    hide,
    fullRow,
    fullNameValue,
    handleClientName,
    editClient,
    isDisabled,
    clientFull,
    intl,
    goesEditClient,
    email,
    handleEmailChange,
  } = useClient({
    value,
    onChange,
    disabled,
    isCreateBooking,
    isWithPhone,
  });

  return (
    <>
      <HideWhen condition={hide && isWithPhone} noUnmount>
        <div
          className={cn('book titled-block required', { hasValue: !invalid })}
          style={{ gridColumn: 'span 2' }}
        >
          <div className={commonStyles.guestInfo}>
            <Labeled
              className={styles.bookingLabel}
              required={required}
              label={intl.formatMessage({ id: ETranslations.NAME })}
            >
              <Input
                value={fullNameValue.name}
                autoComplete="off"
                onChange={handleClientName}
                name="name"
                size={1}
                maxLength={160}
                disabled={!editClient || isDisabled}
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({ id: ETranslations.NAME })
                    : undefined
                }
                invalid={!(valid || fullNameValue.name?.trim())}
                inputClassName={commonStyles.greatBoldText}
                required={required}
                suffix={
                  canEditNames
                  && isRealClient(clientFull)
                  && !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                {...props}
              />
            </Labeled>
            <Labeled
              className={styles.bookingLabel}
              required={required}
              label={intl.formatMessage({ id: ETranslations.LAST_NAME })}
            >
              <Input
                value={fullNameValue.surname}
                autoComplete="off"
                onChange={handleClientName}
                name="surname"
                size={1}
                maxLength={160}
                disabled={!editClient || isDisabled}
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({
                        id: ETranslations.LAST_NAME,
                      })
                    : undefined
                }
                invalid={!(valid || fullNameValue.surname?.trim())}
                inputClassName={commonStyles.greatBoldText}
                required={required}
                suffix={
                  canEditNames
                  && isRealClient(clientFull)
                  && !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                {...props}
              />
            </Labeled>
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.MIDDLE_NAME })}
            >
              <Input
                value={fullNameValue.middle_name}
                autoComplete="off"
                onChange={handleClientName}
                name="middle_name"
                size={1}
                maxLength={160}
                disabled={!editClient || isDisabled}
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({
                        id: ETranslations.MIDDLE_NAME,
                      })
                    : undefined
                }
                inputClassName={commonStyles.greatBoldText}
                suffix={
                  canEditNames
                  && isRealClient(clientFull)
                  && !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                {...props}
              />
            </Labeled>
          </div>
        </div>
      </HideWhen>
      <HideWhen condition={hide || (fullRow && !isNewDesign)}>
        <div className="email titled-block add">
          <Labeled
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.EMAIL })}
          >
            <Input
              value={email}
              type="email"
              size={1}
              placeholder="@"
              disabled={isDisabled}
              onChange={handleEmailChange}
              inputClassName={commonStyles.greatBoldText}
            />
          </Labeled>
        </div>
      </HideWhen>
    </>
  );
};

export const ChoiceClientInfoRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => {
  return <ClientInfoRedux {...input} {...meta} {...rest} />;
};
