import React, { createContext, CSSProperties, useLayoutEffect } from 'react';
import { HallSchema } from './HallSchemaV2/hall-schema';
import { useIsTabletBooking } from '../../../hooks/useIsTablet';
import { HallMode, useHallSchemaActions } from '../../../features/HallSchema';
import { useUnmount } from 'react-use';
import { useSelector } from 'react-redux';
import { hallModeSelector } from '../../../features/HallSchema/selectors';
import { usePageId } from '../../../contexts/LayoutContext';
import { SelectGuestFromList } from '../../../features/GuestsList/components/select-guest-from-list';
import {
  HorizontalWindowsLayout,
  HorizontalWindowsLayoutColumn,
  HorizontalWindowsLayoutItem,
} from '../../../common/components/HorizontalWindowLayout/horizontal-windows-layout';
import { useFromProxyActions } from '../../../features/BookingFormProxy';
import { Client } from '../../../models/client.model';
import {
  isBookingView,
  isGuestView,
  isHallView,
  isManagarelTableView,
  isRegView,
  isTableBookingView,
} from './HallSchemaV2/hall-helpersFn';
import { TableBookingList } from './TableBookingList/table-booking-list';
import { TabletHallPopup } from './TabletHallPopup';
import { CreateBooking } from './Booking/CreateBooking';
import { RegisterVisit } from './Booking/RegisterVisit';
import { useMoveBookingActions } from 'features/MoveBooking';
import { CreateManageralForm } from 'components/ManagerialTables/form/CreateManageralForm';
import styles from './HallContainer.module.scss';
import { useIsLandscape } from '../../../hooks/useIsDeviceLandscape';
import { RightSideComponent } from './RightSideComponent';
import { useHistory } from 'react-router';

export const IsMountedInHall = createContext(false);

const DesktopVersion: React.VFC = () => {
  const { reset, switchMode } = useHallSchemaActions();
  const { setClient } = useFromProxyActions();
  const { clearSelection } = useMoveBookingActions();
  useUnmount(reset);
  useUnmount(clearSelection);
  const hallMode = useSelector(hallModeSelector);
  const actionView
    = hallMode !== HallMode.TABLES
    && hallMode !== HallMode.MOVE_BOOKINGS_CAPTURED;

  const desktopGridStyles: CSSProperties = actionView
    ? {
        gridGap: 'var(--header-spacing)',
        gridTemplateColumns: 'max-content 1fr',
      }
    : {
        display: 'block',
      };
  const guestCloseHandler = () => {
    if (hallMode === HallMode.REGISTRATION_GUESTS)
      switchMode(HallMode.REGISTRATION_HALL);
    if (hallMode === HallMode.BOOKING_GUEST) switchMode(HallMode.BOOKING_HALL);
  };
  const selectGuestHandler = (guest: Client) => {
    setClient({ client: guest });
    if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_HALL);
    if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_HALL);
    if (isTableBookingView(hallMode)) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
  };

  // todo: сброс после сабмита формы не отрабатывает
  return (
    <>
      {!actionView ? (
        <HorizontalWindowsLayout styles={desktopGridStyles} name="desktop">
          <HorizontalWindowsLayoutColumn name="component">
            <HallSchema />
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      ) : (
        <HorizontalWindowsLayout styles={desktopGridStyles} name="desktop">
          <HorizontalWindowsLayoutColumn
            name="forms"
            style={{ width: '570px' }}
          >
            {isManagarelTableView(hallMode) && (
              <HorizontalWindowsLayoutItem
                pinned
                name="manageral-booking"
                render={() => <CreateManageralForm reset={reset} />}
              />
            )}
            {isRegView(hallMode) && (
              <HorizontalWindowsLayoutItem
                pinned
                name="registration"
                render={() => <RegisterVisit reset={reset} />}
              />
            )}

            {isBookingView(hallMode) && (
              <HorizontalWindowsLayoutItem
                pinned
                name="booking"
                render={() => <CreateBooking reset={reset} />}
              />
            )}
            {isTableBookingView(hallMode) && (
              <HorizontalWindowsLayoutItem
                active
                name="table-bookings"
                render={() => <TableBookingList />}
              />
            )}
          </HorizontalWindowsLayoutColumn>
          <HorizontalWindowsLayoutColumn name="component">
            {isHallView(hallMode) && (
              <HorizontalWindowsLayoutItem
                active
                name="hall-schema"
                render={() => <HallSchema />}
                initialVisible
              />
            )}
            {isGuestView(hallMode) && (
              <HorizontalWindowsLayoutItem
                active
                name="guest-view"
                render={() => (
                  <SelectGuestFromList
                    onClose={guestCloseHandler}
                    onSelectGuest={(guest) => selectGuestHandler(guest)}
                  />
                )}
              />
            )}
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      )}
    </>
  );
};

const TableVersion = () => {
  const { reset } = useHallSchemaActions();
  useUnmount(reset);
  const hallMode = useSelector(hallModeSelector);
  const isLandscapeDevice = useIsLandscape();
  const formByMode = () => {
    switch (hallMode) {
      case HallMode.MANAGARAL_TABLET:
      case HallMode.MANAGARAL_HALL:
      case HallMode.MANAGARAL_BOOKING:
        return <CreateManageralForm reset={reset} />;
      case HallMode.TABLES:
      case HallMode.MOVE_BOOKINGS_CAPTURED:
        return <HallSchema />;
      case HallMode.REGISTRATION_TABLET:
      case HallMode.REGISTRATION_GUESTS:
      case HallMode.REGISTRATION_HALL:
        return <RegisterVisit reset={reset} />;
      case HallMode.BOOKING_TABLET:
      case HallMode.BOOKING_GUEST:
      case HallMode.BOOKING_HALL:
        return <CreateBooking reset={reset} />;
      case HallMode.TABLE_BOOKINGS_LIST:
      case HallMode.TABLE_BOOKINGS_EDIT:
      case HallMode.TABLE_BOOKINGS_EDIT_GUEST:
      case HallMode.EDIT_HALL:
        return <TableBookingList />;
      default:
        return <HallSchema />;
    }
  };

  if (isLandscapeDevice)
    return (
      <>
        <HorizontalWindowsLayout>
          <HorizontalWindowsLayoutColumn name="tablet">
            <HorizontalWindowsLayoutItem
              name="form"
              render={() => formByMode()}
              initialVisible
            />
            <HorizontalWindowsLayoutItem
              name="hall"
              pinned
              render={() => <TabletHallPopup hallMode={hallMode} />}
            />
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      </>
    );

  return (
    <div className={styles.tabletBookingContainer}>
      {formByMode()}
      <RightSideComponent hallMode={hallMode} />
    </div>
  );
};

export const HallContainer: React.FC<{ isCreateBookingOpened?: boolean }> = ({
  isCreateBookingOpened,
}) => {
  const isTablet = useIsTabletBooking();
  const { switchMode } = useHallSchemaActions();
  const { location }
    = useHistory<{ from?: string; isRegistrationVisit?: boolean }>();
  usePageId('hall-scheme');

  // Меняет вью в зависимости от ширины экрана и стейта. Крайне желательно заменить все это на адекватный роутинг
  useLayoutEffect(() => {
    if (isCreateBookingOpened) {
      location.state.isRegistrationVisit
        ? switchMode(
            isTablet
              ? HallMode.REGISTRATION_TABLET
              : HallMode.REGISTRATION_GUESTS
          )
        : switchMode(
            isTablet ? HallMode.BOOKING_TABLET : HallMode.BOOKING_GUEST
          );
    }
  }, [isCreateBookingOpened]);

  return (
    <IsMountedInHall.Provider value>
      {isTablet ? <TableVersion /> : <DesktopVersion />}
    </IsMountedInHall.Provider>
  );
};
